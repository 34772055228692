/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-3/5">
        <vx-card class="p-4">
          <img alt="" class="responsive" src="https://rapidsimulations.com/simulations/6/images/Untitled-1-Recovered-p-800.png" />
        </vx-card>
      </div>
      <div class="vx-col w-2/5">
        <vx-card>
          <div v-for="section in configureWiFi" :key="section.id" class="p-5 border-solid mb-3 rounded">
            <h3 class="font-bold break-all">{{ section.name }}</h3>
            <vs-divider />
            <div v-for="(field, field_i) in section.fields" :key="field_i" class="mt-4">
              <div class="" v-if="field.type === 'html'" v-html="field.html"></div>
              <div class="vx-row" v-if="field.type !== 'html'">
                <div class="vx-col flex w-1/3">
                  <h3 class="break-all self-center">{{ field.name }}</h3>
                </div>
                <div class="vx-col w-2/3">
                  <v-select
                    v-if="field.type === 'dropdown'"
                    :options="field.options"
                    v-model="field.value"
                    :disabled="field.disabled"
                    class="w-full self-center"
                    placeholder="Select Option From Dropdown..."
                    size="large"
                  />
                  <vs-input
                    v-if="field.type === 'text'"
                    v-model="field.value"
                    :readonly="field.disabled"
                    class="inputx w-full self-center"
                  />
                  <div class="flex items-center" v-if="field.type === 'switch'">
                    <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                    <h4 class="ml-2">{{ field.desc }}</h4>
                  </div>
                  <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                  <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      configureWiFi: [
        {
          id: 0,
          name: 'Android 4.4 - Settings',
          fields: [
            {
              name: '802.11',
              type: 'dropdown',
              value: 'a',
              answer: ['n'],
              disabled: false,
              options: ['a', 'b', 'g', 'n'],
              score: null,
            },
            {
              name: 'SSID',
              type: 'dropdown',
              value: 'Select a SSID',
              answer: ['CORP'],
              disabled: false,
              options: ['BYOD', 'CORP'],
              score: null,
            },
            {
              html: '<br>',
              type: 'html',
            },
            {
              name: 'Frequency',
              type: 'dropdown',
              value: '2.4GHz',
              answer: ['2.4GHz'],
              disabled: false,
              options: ['2.4GHz', '5GHz'],
              score: null,
            },
            {
              html: '<br>',
              type: 'html',
            },
            {
              name: 'Security Mode',
              type: 'dropdown',
              value: 'OPEN',
              answer: ['WPA2'],
              disabled: false,
              options: ['OPEN', 'WEP', 'WPA', 'WPA2', 'WPA2 Enterprise'],
              score: null,
            },
            {
              name: 'Passphrase',
              type: 'text',
              value: '',
              answer: ['Corpsecure1'],
              disabled: false,
              score: null,
            },
          ],
        },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.configureWiFi.forEach((category, category_i) => {
        if (category.fields) {
          category.fields.forEach((field, field_i) => {
            if (field.type !== 'html') {
              if (field.answer.includes(field.value)) {
                this.configureWiFi[category_i].fields[field_i].score = true;
                totalScore++;
              } else {
                this.configureWiFi[category_i].fields[field_i].score = false;
              }
            }
          });
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx201394311844069376.scss';

button.vs-tabs--btn {
  color: #ffffff;
}
</style>
